import React from 'react'
import styled from 'styled-components'
import { useForm, ValidationError } from '@formspree/react'
import '../styles/typography.css'

const FormWrapper = styled.div`
  max-width: 900px;
  margin: 20px auto;
  padding-left: 10px;
  padding-right: 10px;
`

const FormStyled = styled.form`
  position: relative;
  margin-bottom: 20px;
  display: block;
  /* reset */
  input,
  select,
  textarea,
  fieldset,
  optgroup,
  label {
    font-family: inherit;
    font-size: 100%;
    color: inherit;
    border: none;
    border-radius: 0;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  label,
  legend {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    font-family: 'Zing', Arial, Helvetica, sans-serif;

  }
  /* border, padding, margin, width */
  input,
  select,
  textarea {
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0.75em 1rem;
    margin-bottom: 1.5rem;
  }
  input:focus,
  select:focus,
  textarea:focus {
    background-color: white;
    outline-style: solid;
    outline-width: thin;
    outline-color: gray;
    outline-offset: -1px;
  }
  form [type="text"],
  form [type="email"] {
    width: 100%;
  }
  [type="button"],
  [type="submit"],
  [type="reset"] {
      font-family: 'Zing', Arial, Helvetica, sans-serif;
      width: auto;
      cursor: pointer;
      font-size: 24px;
      background: #f60057;
      color: #fff;
      border: none;
      padding: 20px 30px;
      border-radius: 100px;
      -webkit-transform: translateY(10px);
      -ms-transform: translateY(10px);
      transform: translateY(10px);
      display: block;
      margin: 0 auto;
      position: relative;
      text-align: center;
      z-index: 3;
  }
  [type="button"]:focus,
  [type="submit"]:focus,
  [type="reset"]:focus {
    outline: none;
  }

  [type="submit"]:hover, 
  [type="submit"]:focus {
      transform: scale(1.2);
      transform-origin: center;

  }

  select {
    text-transform: none;
  }
`

const formKey = process.env.GATSBY_FORMSPREE_FORM_KEY

const ContactForm = () => {

  const [state, handleSubmit] = useForm(formKey.toString())

  if (state.succeeded) {
      return <p>Thanks for getting in touch!</p>
  }
  return (
      <FormWrapper>
        <FormStyled onSubmit={handleSubmit}>
        <label htmlFor="email">
            Email Address
        </label>
        <input
            id="email"
            type="email" 
            name="email"
        />
        <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
        />
        <label htmlFor="message">
            Let us know how we can help
        </label>
        <textarea
            id="message"
            name="message"
        />
        <ValidationError 
            prefix="Message" 
            field="message"
            errors={state.errors}
        />
        <button type="submit" disabled={state.submitting}>
            Send us a message
        </button>
        </FormStyled>
    </FormWrapper>
  );
}

export default ContactForm
