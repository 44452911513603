import * as React from "react"
import Menu from '../components/menu'
import Footer from '../components/footer'
import FooterBanner from '../components/footer-banner'
import SubPageHero from '../components/sub-page-hero'
import HelmetInfo from '../components/helmet-info'
import ContactForm from "../components/contact-form"
import '../styles/typography.css'
import squiggles from '../images/squiggles.svg'
import 'animate.css'

const ContactUs = () => {
  return (
    <>
    <HelmetInfo />
    <Menu/>
    <main>
        <SubPageHero>
            <h1 className="animate__animated animate__bounceInDown">Contact us</h1>
            <img className="hero-squiggles" src={squiggles} alt="decorative squiggles" />
        </SubPageHero>
        <ContactForm />
        <h2 className="email-note">Or just drop us an email:<br/><strong>barry@fizzpack.com</strong></h2>
        </main>
        <FooterBanner title="Check out our portfolio" linkUrl="/portfolio" linkText="Check it out"/>
    <Footer />
    </>
  )
}

export default ContactUs
